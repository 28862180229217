<template>
  <v-container>
    <div v-show="dataFetch" class="mt-3">
      <LoadingData @closeDataFetch="closeDataFetch"></LoadingData>
    </div>
  </v-container>
</template>
<script>
import LoadingData from "@/components/templates/LoadingData";
export default {
  components: {
    LoadingData,
  },
  data: () => ({
    dataFetch: true,
  }),
  methods: {
    closeDataFetch() {
      //clear cart data and totals
      this.dataFetch = false;
      this.$router.push({ name: "DashboardShop" });
    },
  },
};
</script>
