<template>
  <v-container style="height: 400px">
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
      v-if="loading"
    >
      <v-col class="text-subtitle-1 text-center" cols="12"
        >{{ $t("fetching data...") }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          :color="this.$store.state.primaryColor"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
    <v-row
      class="fill-height"
      align-content="center"
      justify="center"
      v-else-if="refreshing"
    >
      <v-col class="text-subtitle-1 text-center" cols="12"
        >{{ $t("refreshing...") }}
      </v-col>
      <v-col cols="6">
        <v-progress-linear
          :color="this.$store.state.orange"
          indeterminate
          rounded
          height="6"
        ></v-progress-linear>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import db from "@/plugins/fb";
export default {
  data: () => ({
    loading: true,
    refreshing: false,
    accounts: [],
    products: [],
    suppliers: [],
    currencies: [],
    licenses: [],
    purchaseOrders: [],
    sales: [],
    orders: [],
    receipts: [],
    services: [],
    categories: [],
    projects: [],
    payroll: [],
    employees: [],
    shifts: [],
    expenseClaims: [],
    overtimes: [],
    menu: [
      { name: "accounts" },
      { name: "projects" },
      { name: "suppliers" },
      { name: "transactions" },
    ],
  }),
  computed: {
    selected() {
      return this.$store.getters.getCurrentBusiness;
    },

    ownersBusinessesId() {
      return this.$store.getters.getBusinessId;
    },

    ownersBusinesses() {
      return this.$store.getters.getBusinesses;
    },
  },
  created() {
    this.currentUser = this.$store.state.currentUser;
    this.getStock();
  },
  methods: {
    getStock() {
      this.products = [];
      if (this.selected) {
        db.collection("products")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getAccounts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getStock();
          });
      } else {
        db.collection("products")
          .where("businessID", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.products.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getAccounts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getStock();
          });
      }
    },
    getAccounts() {
      this.loading = true;
      this.refreshing = false;
      this.accounts = [];
      if (this.selected) {
        db.collection("accounts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });

            //keep accounts in vuex store
            this.$store.commit("SET_ACCOUNTS", this.accounts);
            this.getSuppliers();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getAccounts();
          });
      } else {
        db.collection("accounts")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.accounts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            //keep accounts in vuex store
            this.$store.commit("SET_ACCOUNTS", this.accounts);
            this.getSuppliers();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getAccounts();
          });
      }
    },
    getSuppliers() {
      this.loading = true;
      this.refreshing = false;
      this.suppliers = [];
      if (this.selected) {
        db.collection("suppliers")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getReceipts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getSuppliers();
          });
      } else {
        db.collection("suppliers")
          .where("businessID", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.suppliers.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getReceipts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getSuppliers();
          });
      }
    },
    getReceipts() {
      this.loading = true;
      this.refreshing = false;
      this.receipts = [];
      if (this.selected) {
        db.collection("receipts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getOrders();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getReceipts();
          });
      } else {
        db.collection("receipts")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.receipts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getOrders();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getReceipts();
          });
      }
    },
    getOrders() {
      this.loading = true;
      this.refreshing = false;
      this.orders = [];
      if (this.selected) {
        db.collection("orders")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getCategories();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getOrders();
          });
      } else {
        db.collection("orders")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.orders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getCategories();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getOrders();
          });
      }
    },
    getCategories() {
      this.loading = true;
      this.refreshing = false;
      this.categories = [];
      db.collection("productCategory")
        .get()
        .then((querySnapshot) => {
          querySnapshot.forEach((doc) => {
            this.categories.push({
              id: doc.id,
              category: doc.data().category,
            });
          });
          this.getProjects();
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = true;
          this.getCategories();
        });
    },
    getProjects() {
      this.loading = true;
      this.refreshing = false;
      this.projects = [];
      if (this.selected) {
        db.collection("projects")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.projects.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getServices();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getProjects();
          });
      } else {
        db.collection("projects")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.projects.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getServices();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getProjects();
          });
      }
    },
    getServices() {
      this.loading = true;
      this.refreshing = false;
      this.services = [];
      if (this.selected) {
        db.collection("services")
          .where("businessID", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.services.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getSales();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getServices();
          });
      } else {
        this.loading = true;
        this.refreshing = false;
        db.collection("services")
          .where("businessID", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.services.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getSales();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getServices();
          });
      }
    },
    getSales() {
      this.loading = true;
      this.refreshing = false;
      this.sales = [];
      if (this.selected) {
        db.collection("transactions")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getPurchaseOrders();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getSales();
          });
      } else {
        db.collection("transactions")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.sales.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getPurchaseOrders();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getSales();
          });
      }
    },
    getPurchaseOrders() {
      this.loading = true;
      this.refreshing = false;
      this.purchaseOrders = [];
      if (this.selected) {
        db.collection("purchaseOrders")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.purchaseOrders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getShifts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getPurchaseOrders();
          });
      } else {
        db.collection("purchaseOrders")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.purchaseOrders.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getShifts();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getPurchaseOrders();
          });
      }
    },
    getShifts() {
      this.loading = true;
      this.refreshing = false;
      this.shifts = [];
      if (this.selected) {
        db.collection("shifts")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getEmployees();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getShifts();
          });

        this.loading = false;
      } else {
        db.collection("shifts")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.shifts.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getEmployees();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getShifts();
          });
      }
    },

    getEmployees() {
      this.loading = true;
      this.refreshing = false;
      this.employees = [];
      if (this.selected) {
        db.collection("employees")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getPayrolls();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getEmployees();
          });
      } else {
        db.collection("employees")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.employees.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getPayrolls();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getEmployees();
          });
      }
    },
    getPayrolls() {
      this.loading = true;
      this.refreshing = false;
      this.payroll = [];
      if (this.selected) {
        db.collection("payroll")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.payroll.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getOvertimes();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getPayrolls();
          });
      } else {
        db.collection("payroll")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.payroll.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getOvertimes();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getPayrolls();
          });
      }
    },
    getOvertimes() {
      this.loading = true;
      this.refreshing = false;
      this.overtimes = [];
      if (this.selected) {
        db.collection("overtimes")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.overtimes.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getExpenseClaims();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getOvertimes();
          });
      } else {
        db.collection("overtimes")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.overtimes.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getExpenseClaims();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getOvertimes();
          });
      }
    },
    getExpenseClaims() {
      this.loading = true;
      this.refreshing = false;
      this.expenseClaims = [];
      if (this.selected) {
        db.collection("expenseClaims")
          .where("businessId", "==", this.selected)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.expenseClaims.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getCurrencies();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getExpenseClaims();
          });
      } else {
        db.collection("expenseClaims")
          .where("businessId", "in", this.ownersBusinessesId)
          .get()
          .then((querySnapshot) => {
            querySnapshot.forEach((doc) => {
              this.expenseClaims.push({
                id: doc.id,
                ...doc.data(),
              });
            });
            this.getCurrencies();
          })
          .catch(() => {
            this.loading = false;
            this.refreshing = true;
            this.getExpenseClaims();
          });
      }
    },
    getCurrencies() {
      this.loading = true;
      this.refreshing = false;
      this.currencies = [];
      db.collection("currencies")
        .get()
        .then((snapshot) => {
          if (snapshot.size > 0) {
            snapshot.forEach((snaps) => {
              this.currencies.push({
                id: snaps.id,
                selected: false,
                ...snaps.data(),
              });

              this.grabCurrencies(snaps.id);
              this.currencies = this.currencies.sort((a, b) =>
                a.name < b.name ? -1 : 1
              );
            });
          }
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = true;
          this.getCurrencies();
        });
    },
    grabCurrencies(currencyId) {
      this.loading = true;
      this.refreshing = false;
      db.collection("configurations")
        .doc(this.currentUser)
        .get()
        .then((snaps) => {
          if (snaps.exists) {
            if (snaps.data().currency == currencyId) {
              var index = this.currencies.findIndex((x) => x.id == currencyId);
              this.currencies[index].selected = true;
              let currencydata;
              db.collection("currencies")
                .doc(currencyId)
                .get()
                .then((doc) => {
                  currencydata = {
                    owner: this.currentUser,
                    id: doc.id,
                    ...doc.data(),
                  };
                  this.$store.commit("SET_CURRENCY", currencydata);
                  this.getLicense();
                });
            }
          }
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = true;
          this.getCurrencies();
        });
    },
    getLicense() {
      this.loading = true;
      this.refreshing = false;
      this.licenses = [];
      db.collection("licenses")
        .where("ownerId", "==", this.currentUser)
        .get()
        .then((snapshot) => {
          snapshot.forEach((snaps) => {
            db.collection("packages")
              .doc(snaps.data().packageId)
              .get()
              .then((snap) => {
                this.licenses.push({
                  id: snaps.id,
                  ...snaps.data(),
                  ...snap.data(),
                });
              });
          });
          this.redirect();
        })
        .catch(() => {
          this.loading = false;
          this.refreshing = true;
          this.getLicense();
        });
    },
    redirect() {
      this.$emit("closeDataFetch");
    },
  },
};
</script>
